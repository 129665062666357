import { gql } from "@apollo/client";
import customerFeatureSettingFragment from "./customerFeatureSettingFragment";

export const customerShortFragment = gql`
  fragment customerShortFragment on Customer {
    id
    title
    integrations {
      constantContact {
        exists
      }
    }
    features {
      ...customerFeatureSettingFragment
    }
    maximumEmailsPerDay
  }
  ${customerFeatureSettingFragment}
`;
