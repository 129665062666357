// TOP LEVEL IMPORTS
import { lazy } from "react";
// ROUTE CONTAINERS
import PublicRoute from "components/route-components/PublicRoute";
import ProtectedRoute from "components/route-components/ProtectedRoute"; // will Navigate them to login if signed out
// LAYOUTS
import MobileAuthenticatedLayout from "components/layout/MobileAuthenticatedLayout";
import PublicLayout from "components/layout/PublicLayout";
// COMPONENTS
import AppSurvey from "../app-survey/index3";
import AppHome from "../app-home";
import AppDetail from "./app-detail-page";
import PublicLegal from "routes/public-legal";
import AuthLoginRoute from "routes/auth-login";
import AuthForgotPassword from "routes/auth-forgot-password";
import ResetPassword from "routes/auth-reset-password";
import AppYourWork from "routes/app-your-work";
import { Route, Routes } from "react-router-dom";
import BlankRoute from "components/route-components/BlankRoute";
import PublicSurveyView from "routes/public-survey-view";

export default function MobileRouter2({ currentUser, windowWidth }) {
  const defaultProps = {
    currentUser,
    windowWidth,
  };

  return (
    <Routes>
      {/* HOME APP ROUTES */}

      {[
        "/app/cases",
        "/app/resources",
        "/app/projects",
        "/app/businesses",
        "/app/contacts",
      ]?.map((path) => {
        return (
          <Route
            path={path}
            key={path}
            element={
              <ProtectedRoute
                exact
                {...defaultProps}
                layout={MobileAuthenticatedLayout}
                component={AppHome}
              />
            }
          />
        );
      })}

      {["/app/projects/:id", "/app/businesses/:id", "/app/contacts/:id"]?.map(
        (path) => {
          return (
            <Route
              path={path}
              key={path}
              element={
                <ProtectedRoute
                  exact
                  layout={MobileAuthenticatedLayout}
                  {...defaultProps}
                  component={AppDetail}
                />
              }
            />
          );
        }
      )}

      <Route
        path="/app/dashboard"
        element={
          <ProtectedRoute
            exact
            layout={MobileAuthenticatedLayout}
            {...defaultProps}
            component={AppHome}
          />
        }
      />
      <Route
        path="/app/survey/view"
        element={
          <BlankRoute
            exact
            layout={PublicLayout}
            currentUser={currentUser}
            component={PublicSurveyView}
          />
        }
      />
      <Route
        path="/app/survey"
        element={
          <ProtectedRoute
            exact
            layout={MobileAuthenticatedLayout}
            {...defaultProps}
            component={AppSurvey}
            hideActionBtn={true}
          />
        }
      />
      <Route
        path="/app/your-work"
        element={
          <ProtectedRoute
            exact
            layout={MobileAuthenticatedLayout}
            {...defaultProps}
            component={AppYourWork}
          />
        }
      />
      {/*  PUBLIC ROUTES */}

      <Route
        path="/forgot-password"
        element={
          <PublicRoute
            exact
            layout={PublicLayout}
            {...defaultProps}
            component={AuthForgotPassword}
          />
        }
      />
      <Route
        path="/reset-password/:token"
        element={
          <PublicRoute
            exact
            layout={PublicLayout}
            {...defaultProps}
            component={ResetPassword}
          />
        }
      />
      <Route
        path="/set-password/:token"
        element={
          <PublicRoute
            exact
            layout={PublicLayout}
            {...defaultProps}
            component={ResetPassword}
          />
        }
      />

      {["/", "/login"]?.map((path) => {
        return (
          <Route
            path={path}
            key={path}
            element={
              <PublicRoute
                exact
                layout={PublicLayout}
                {...defaultProps}
                component={AuthLoginRoute}
              />
            }
          />
        );
      })}

      <Route
        path="/legal/:page"
        element={
          <PublicRoute
            exact
            layout={PublicLayout}
            {...defaultProps}
            component={PublicLegal}
          />
        }
      />
    </Routes>
  );
}
