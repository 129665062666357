import moment from "moment";
// COMPONENTS
import TextInput from "components/inputs/TextInput";
import TextAreaInput from "components/inputs/TextAreaInput";
import NumberInput from "components/inputs/NumberInput";
import DateInput from "components/inputs/DateInput";
import NaicsInput from "components/inputs/NaicsInput";
import CurrencyInput from "components/inputs/CurrencyInput";
import DiversityInput from "components/inputs/DiversityInput";
import CustomTagsInput from "components/inputs/CustomTagsInput/index";
import BusinessSectorInput from "../../inputs/BusinessSectorInput/index";
import ClustersMultiselectInput from "components/inputs/ClustersMultiselectInput";
import { DetailitemFieldTypeEnum } from "components/detail-components/DetailItem";
import { SiteExtraFieldTypeEnum } from "generated/graphql";
import TextEditor from "components/inputs/TextEditor";
import ContactInput from "components/inputs/ContactInputSimple";
import SiteTypeInput from "components/inputs/SiteTypeInput";
import Row from "components/common/Row";
import Col from "components/common/Col";
import UserInput from "components/inputs/UserInput";
import PhoneInput from "components/inputs/PhoneInput";
import BusinessStatusInput from "components/inputs/BusinessStatusInput";
import { Select } from "antd";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";

const { Option } = Select;

interface CustomFieldProps {
  value?: string;
  fieldType?: DetailitemFieldTypeEnum | SiteExtraFieldTypeEnum;
  onChange: any;
  style?: React.CSSProperties;
}

export default function CustomField({
  value,
  fieldType,
  onChange,
  style,
}: CustomFieldProps) {
  if (fieldType === DetailitemFieldTypeEnum.fullName) {
    return (
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <TextInput
            value={value?.[0]}
            style={style}
            onChange={(newValue) => onChange([newValue, value?.[1]])}
          />
        </Col>
        <Col xs={24} sm={12}>
          <TextInput
            value={value?.[1]}
            style={style}
            onChange={(newValue) => onChange([value?.[0], newValue])}
          />
        </Col>
      </Row>
    );
  }

  if (fieldType === DetailitemFieldTypeEnum.optResult) {
    return (
      <DropdownStyleWrapper>
        <Select
          style={{ width: "65%" }}
          onChange={(newValue) => onChange(newValue)}
          data-testid="open-to-communicaiton"
        >
          <Option value={false}>Subscribed</Option>
          <Option value={true}>Unsubscribed</Option>
          <Option value={null}>Unknown</Option>
        </Select>
      </DropdownStyleWrapper>
    );
  }

  if (fieldType === DetailitemFieldTypeEnum.text) {
    return (
      <TextInput
        value={value}
        style={style}
        onChange={(newValue) => onChange(newValue)}
      />
    );
  }
  if (fieldType === DetailitemFieldTypeEnum.currency) {
    return (
      <div>
        <CurrencyInput
          value={value}
          style={{
            ...style,
            minWidth: 200,
            backgroundColor: "#fff",
          }}
          defaultValue={value}
          onChange={(newValue) => onChange(newValue)}
        />
      </div>
    );
  }

  if (fieldType === DetailitemFieldTypeEnum.contact) {
    return (
      <ContactInput
        value={value}
        defaultContactId={value}
        onChange={(newValue) => onChange(newValue)}
      />
    );
  }

  if (fieldType === DetailitemFieldTypeEnum.businessStatus) {
    return (
      <BusinessStatusInput
        value={value}
        onChange={(newValue) => onChange(newValue)}
      />
    );
  }

  if (fieldType === DetailitemFieldTypeEnum.siteType) {
    return (
      <SiteTypeInput
        value={value}
        onChange={(newValue) => onChange(newValue)}
      />
    );
  }

  if (fieldType === DetailitemFieldTypeEnum.phone) {
    return (
      <PhoneInput value={value} onChange={(newValue) => onChange(newValue)} />
    );
  }

  if (fieldType === DetailitemFieldTypeEnum.texteditor) {
    return (
      <TextEditor
        value={value}
        style={style}
        onChange={(description) => onChange(description)}
        data-testid="note-form-description"
      />
    );
  }

  if (fieldType === DetailitemFieldTypeEnum.date) {
    let dateValue;
    if (value) {
      dateValue = moment(parseInt(value));
    }
    return (
      <DateInput
        value={dateValue}
        format="M/D/YY"
        onChange={(newValue) => onChange(newValue.valueOf().toString())}
      />
    );
  }
  if (fieldType === DetailitemFieldTypeEnum.naics) {
    return (
      <div>
        <NaicsInput value={value} onChange={(newValue) => onChange(newValue)} />
      </div>
    );
  }

  if (fieldType === DetailitemFieldTypeEnum.clusters) {
    return (
      <div>
        <ClustersMultiselectInput
          value={value}
          onChange={(newValue) => onChange(newValue)}
        />
      </div>
    );
  }

  if (fieldType === DetailitemFieldTypeEnum.tags) {
    return (
      <div>
        <CustomTagsInput
          value={value}
          onChange={(newValue) => onChange(newValue)}
        />
      </div>
    );
  }

  if (fieldType === DetailitemFieldTypeEnum.businessSector) {
    return (
      <div>
        <BusinessSectorInput
          value={value}
          onChange={(newValue) => onChange(newValue)}
        />
      </div>
    );
  }

  if (fieldType === DetailitemFieldTypeEnum.diversity) {
    return (
      <DiversityInput
        value={value}
        onChange={(newValue) => onChange(newValue)}
      />
    );
  }

  if (fieldType === DetailitemFieldTypeEnum.textarea) {
    return (
      <TextAreaInput
        value={value}
        style={style}
        onChange={(newValue) => onChange(newValue)}
      />
    );
  }

  if (fieldType === DetailitemFieldTypeEnum.team) {
    return (
      <UserInput value={value} onChange={(newValue) => onChange(newValue)} />
    );
  }

  if (fieldType === DetailitemFieldTypeEnum.number) {
    return (
      <div>
        <NumberInput
          style={style}
          value={value}
          onChange={(newValue) => onChange(newValue)}
        />
      </div>
    );
  }
  return null;
}
