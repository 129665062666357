import TextEditor from "components/inputs/TextEditor";
import Caption from "components/text/Caption";
import Button from "components/common/Button";
import Col from "components/common/Col";
import Row from "components/common/Row";
import { PageTitle } from "routes/app-automations-create/shared";
import styled from "styled-components";
import TemplateVariableOptions from "../TemplateVariableOptions";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

export default function EmailCampaignEmailEditor({
  emailCampaign,
  onChange,
  onNext,
  loading,
}) {
  return (
    <>
      <PageTitle style={{ marginBottom: 0 }}>Email Editor</PageTitle>
      <Caption style={{ marginBottom: 16 }}>
        Use the editor to create your email template.
      </Caption>
      <TextEditor
        value={
          typeof emailCampaign?.emailTemplate !== "string"
            ? emailCampaign?.emailTemplate
            : emailCampaign?.emailTemplate
        }
        onChange={(emailTemplate) => {
          onChange("emailTemplate", emailTemplate);
        }}
      />
      <Row>
        <Col xs={24} sm={12}>
          <TemplateVariableOptions emailCampaign={emailCampaign} />
        </Col>
        <Col xs={24} sm={12}>
          <ButtonContainer
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              onClick={onNext}
              disabled={loading}
              loading={loading}
              secondary
            >
              Save & Next
            </Button>
          </ButtonContainer>
        </Col>
      </Row>
    </>
  );
}
