import PageContainer from "components/common/PageContainer";
import {
  DataDiscoveryBusinessFragmentFragment,
  useGetOneDataDiscoveryRunQuery,
  useIgnoreDataDiscoveryBusinessMutation,
  useIgnoreOneDataDiscoveryContactMutation,
  useMergeDiscoveredBusinessesMutation,
} from "generated/graphql";
import usePaginationQueryParams from "hooks/usePaginationQueryParams";
import { Link, useParams } from "react-router-dom";
import Table from "components/common/Table";
import Text from "components/text/Text";
import { useEffect, useState } from "react";
import Caption from "components/text/Caption";
import { Popover, Segmented, Tooltip, message } from "antd";
import "antd/lib/segmented/style/css";
import LinkButton from "components/common/LinkButton";
import Tag from "components/common/Tag";
import Button from "components/common/Button";
import Row from "components/common/Row";
import Col from "components/common/Col";
import Title from "components/text/Title";
import moment from "moment";
import { BackwardFilled, LinkedinOutlined } from "@ant-design/icons";

const getOtherVariables = (selected) => {
  let variables = {};
  if (selected === "all") return variables;
  if (selected === "new") {
    variables = {
      ...variables,
      newBusinesses: true,
    };
  }
  if (selected === "ignored") {
    variables = {
      ...variables,
      ignored: true,
    };
  }
  if (selected === "hasContacts") {
    variables = {
      ...variables,
      hasContacts: true,
      newBusinesses: true,
    };
  }
  return variables;
};

export default function DiscoveryRunDetail() {
  const [selected, setSelected] = useState<string>("all");

  const { runId } = useParams();
  const {
    skip,
    limit,
    currentPage,
    onTablePaginationChange,
    resetTableParamsToDefault,
    setCurrentPage,
  } = usePaginationQueryParams();

  const [mergeDiscoveredBusinessesMutation, { loading: merging }] =
    useMergeDiscoveredBusinessesMutation();

  const [ignoreOneDataDiscoveryContactMutation, { loading: ignoringContact }] =
    useIgnoreOneDataDiscoveryContactMutation();

  const [ignoreDataDiscoveryBusinessMutation, { loading: ignoring }] =
    useIgnoreDataDiscoveryBusinessMutation();

  useEffect(() => {
    resetTableParamsToDefault();
  }, [resetTableParamsToDefault]);

  const { data, loading } = useGetOneDataDiscoveryRunQuery({
    variables: {
      runId: runId ? runId : "",
      ...getOtherVariables(selected),
      pagination: {
        skip,
        limit,
      },
    },
    skip: !runId,
  });
  return (
    <PageContainer>
      <Link to={`/app/discovery/runs`}>
        <BackwardFilled /> Back to Discovery Runs
      </Link>
      <Row style={{ marginBottom: 24, marginTop: 40 }} align={"top"}>
        <Col xs={16}>
          <Title>
            Discovered on{" "}
            {data?.getOneDataDiscoveryRun?.createdAt &&
              moment(parseInt(data?.getOneDataDiscoveryRun?.createdAt))?.format(
                "MMMM DD, YYYY @ h:mma"
              )}{" "}
            | {data?.getOneDataDiscoveryRun?.businessesFound} new businesses
          </Title>
          <Caption>
            These are the results of a search for businesses and business
            contacts in your area. You can review the results, ignore results
            you are not interested in, and then import the findings into your
            main business and contact database.
          </Caption>
          <div style={{ marginTop: 16, marginBottom: 16 }}>
            <Text style={{ marginBottom: 4 }}>Searched for:</Text>
            {data?.getOneDataDiscoveryRun?.searchTerms?.map((tag) => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </div>
          <Segmented
            value={selected}
            onChange={(newValue) => {
              setSelected(newValue as string);
              setCurrentPage(1);
            }}
            style={{ marginTop: 16 }}
            options={[
              { label: "All Businesses", value: "all" },
              { label: "Newly Discovered", value: "new" },
              { label: "Newly Discovered with Contacts", value: "hasContacts" },
              { label: "Ignored Businesses", value: "ignored" },
            ]}
          />
        </Col>
        <Col xs={8}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Tooltip
              title={
                !data?.getOneDataDiscoveryRun?.mergedAt
                  ? "Merge Discovery with your Database"
                  : `This discovery run was already merged on ${moment(
                      parseInt(data?.getOneDataDiscoveryRun?.mergedAt)
                    )?.format("MM/DD/YYYY")}`
              }
            >
              <span>
                <Button
                  style={{ marginBottom: 16 }}
                  loading={merging}
                  onClick={async () => {
                    try {
                      if (typeof runId === "string") {
                        await mergeDiscoveredBusinessesMutation({
                          variables: {
                            runId,
                          },
                          refetchQueries: ["getOneDataDiscoveryRun"],
                        });
                        message.loading("Your merge is now running");
                      }
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                  disabled={
                    data?.getOneDataDiscoveryRun?.mergedAt ? true : false
                  }
                >
                  Merge Discovery
                </Button>
              </span>
            </Tooltip>
          </div>
        </Col>
      </Row>

      <Table
        dataSource={data?.getOneDataDiscoveryRun?.businesses?.results}
        loading={loading}
        rowKey="id"
        scroll={{ x: 1300 }}
        pagination={{
          total: data?.getOneDataDiscoveryRun?.businesses?.count,
          pageSize: limit,
          onChange: (page, pageSize) => {
            onTablePaginationChange(page, pageSize);
          },
          showSizeChanger: true,
          current: currentPage,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "16px",
                position: "relative",
              }}
            >
              {record?.contacts?.map((contact) => {
                return (
                  <div
                    key={contact?.email + contact?.cellPhone}
                    style={{
                      border: "1px solid white",
                      borderRadius: "8px",
                      padding: "16px",
                      width: "calc(33% - 16px)",
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "#fff",
                      position: "relative",
                    }}
                  >
                    {contact?.ignored ? (
                      <Tag
                        red
                        style={{
                          position: "absolute",
                          top: "8px",
                          right: "8px",
                        }}
                      >
                        ignored
                      </Tag>
                    ) : (
                      <Tooltip title="If you do not want to include this contact in your merge, you can ignore it">
                        <LinkButton
                          danger
                          disabled={ignoringContact}
                          onClick={async () => {
                            try {
                              if (contact.email) {
                                await ignoreOneDataDiscoveryContactMutation({
                                  variables: {
                                    discoveryBusinessId: record.id,
                                    discoveryContactEmail: contact.email,
                                  },
                                });
                                message.success("Contact ignored successfully");
                              }
                            } catch (err) {
                              console.log(err);
                            }
                          }}
                          style={{
                            position: "absolute",
                            top: "8px",
                            right: "8px",
                          }}
                        >
                          {!ignoringContact ? "Ignore" : "..."}
                        </LinkButton>
                      </Tooltip>
                    )}
                    <Text style={{ fontWeight: "bold" }}>
                      {contact?.firstName} {contact?.lastName}{" "}
                      {contact?.linkedin && (
                        <a
                          href={contact.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ margin: "0 0 4px 0", fontSize: 18 }}
                        >
                          <LinkedinOutlined />
                        </a>
                      )}
                    </Text>
                    <Caption style={{ margin: "0 0 4px 0" }}>
                      {contact?.title}
                    </Caption>
                    <Text style={{ margin: "0 0 4px 0", color: "#555" }}>
                      {contact?.cellPhone}
                    </Text>
                    <Text style={{ margin: "0 0 4px 0", color: "#555" }}>
                      {contact?.email}
                    </Text>
                    <Text style={{ margin: "0", color: "#555" }}>
                      {contact?.city}, {contact?.state}
                    </Text>
                  </div>
                );
              })}
            </div>
          ),
          rowExpandable: (record) => record?.contacts?.[0],
        }}
        columns={[
          {
            title: "Name",
            key: "id",
            width: 450,
            render: (row: DataDiscoveryBusinessFragmentFragment) => {
              return (
                <Row>
                  <Col xs={4}>
                    {row?.logo?.url && (
                      <img
                        src={row?.logo?.url}
                        alt={row?.title || ""}
                        width="32"
                      />
                    )}
                  </Col>
                  <Col xs={20}>
                    <Text>
                      <strong>
                        {row?.title}{" "}
                        {!row?.ignored && (
                          <>
                            {" "}
                            {!row?.existingBusinessId ? (
                              <Tag style={{ marginLeft: 4 }}>New Discovery</Tag>
                            ) : (
                              <Tag grey style={{ marginLeft: 4 }}>
                                Existing
                              </Tag>
                            )}{" "}
                          </>
                        )}
                        {row?.ignored && (
                          <Tag red style={{ marginLeft: 4 }}>
                            ignored
                          </Tag>
                        )}
                      </strong>
                    </Text>
                    <Caption>{row?.description}</Caption>
                  </Col>
                </Row>
              );
            },
          },
          {
            title: "Address",
            width: 250,
            render: (row: DataDiscoveryBusinessFragmentFragment) =>
              row?.address,
          },

          {
            title: "Domain",
            width: 200,
            render: (row: DataDiscoveryBusinessFragmentFragment) => row?.domain,
          },

          {
            title: "NAICS",
            width: 400,
            render: (row: DataDiscoveryBusinessFragmentFragment) => (
              <Text>
                {row?.naics_definition}({row?.naics_code})
              </Text>
            ),
          },

          {
            title: "Actions",
            fixed: "right",
            width: 100,
            render: (row) => {
              return (
                <>
                  {" "}
                  <Popover
                    placement="rightTop"
                    content={
                      <div>
                        <div style={{ marginTop: 8 }}>
                          {row?.google_places_id && (
                            <a
                              href={`https://www.google.com/maps/place/?q=place_id:${row?.google_places_id}
                            `}
                              target="_blank"
                              rel="noreferrer"
                            >
                              View Details
                            </a>
                          )}
                        </div>
                        {!row?.existingBusinessId && !row.ignored && (
                          <div style={{ marginTop: 16, marginBottom: 8 }}>
                            <Tooltip title="This will mark this business to NOT be imported into your master database of businesses">
                              <LinkButton
                                danger
                                onClick={async () => {
                                  try {
                                    if (row?.id) {
                                      await ignoreDataDiscoveryBusinessMutation(
                                        {
                                          variables: {
                                            businessId: row?.id,
                                          },
                                        }
                                      );
                                      message.success(
                                        `${row?.title} will not be included when you merge`
                                      );
                                    }
                                  } catch (err) {}
                                }}
                              >
                                {!ignoring ? "Ignore Business" : "..."}
                              </LinkButton>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    }
                    title="Actions"
                    trigger="click"
                  >
                    <div style={{ textAlign: "center" }}>
                      <LinkButton>...</LinkButton>
                    </div>
                  </Popover>
                </>
              );
            },
          },
        ]}
      />
    </PageContainer>
  );
}
