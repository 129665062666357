// import { Row } from 'react-styled-flexboxgrid';

// export default Row;
import Tag from "antd/lib/tag";
import "antd/lib/tag/style/css";
import styled from "styled-components";

const TagWrapper = styled.span`
  .ant-tag {
    border: 0px !important;
    font-size: 13px;
    font-weight: 500;
    border-radius: 5px;
    background: ${(p) => {
      if (p.grey) {
        return p.theme.colors.neutral10;
      }
      if (p.red) {
        return p.theme.colors.red9;
      }
      if (p.yellow) {
        return p.theme.colors.yellow9;
      }
      if (p.color) {
        return p.color;
      }
      return p.theme.colors.primary9;
    }} !important;
    color: ${(p) => {
      if (p.grey) {
        return p.theme.colors.neutral3;
      }
      if (p.red) {
        return p.theme.colors.red1;
      }
      if (p.yellow) {
        return p.theme.colors.yellow2;
      }
      return p.theme.colors.primary1;
    }} !important;
  }
`;

Tag.defaultProps = {};

const WrappedTag = (props) => (
  <TagWrapper
    grey={props.grey}
    yellow={props.yellow}
    red={props.red}
    color={props.color}
  >
    <Tag size="large" {...props} color={props.color} />
  </TagWrapper>
);

export default WrappedTag;
