import { Tooltip } from "antd";
import Button from "components/common/Button";
import EmptyState from "components/common/EmptyState";
import Loading from "components/common/Loading";
import PageContainer from "components/common/PageContainer";
import Table from "components/common/Table";
import Caption from "components/text/Caption";
import {
  EmailCampaignFragmentFragment,
  EmailCampaignStatusEnum,
  useCreateOneEmailCampaignMutation,
  useGetManyEmailCampaignsQuery,
  useGetManyIntegrationsQuery,
} from "generated/graphql";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";

export default function AppEmailCampaigns() {
  const { data } = useGetManyEmailCampaignsQuery({
    nextFetchPolicy: "cache-and-network",
    fetchPolicy: "cache-and-network",
  });
  const [createOneEmailCampaignMutation, { loading: creating }] =
    useCreateOneEmailCampaignMutation();
  const navigate = useNavigate();
  const { data: integrationsQuery, loading } = useGetManyIntegrationsQuery({
    variables: {
      includeInvalidGrants: false,
    },
    nextFetchPolicy: "cache-and-network",
    fetchPolicy: "cache-and-network",
  }); // create a table of connected emails

  if (loading) return <Loading />;

  if (!integrationsQuery?.getManyIntegrations?.[0]) {
    return (
      <EmptyState
        title="Setup an email integration"
        subtitle="To send email campaigns you must connect your Outlook or Gmail account"
        onOk={() => navigate("/app/settings?sidenav=email")}
        onOkText="Setup Integration"
      />
    );
  }

  // check if they have an email integration

  return (
    <PageContainer>
      <Button
        style={{ marginBottom: 16 }}
        disabled={creating}
        loading={creating}
        onClick={async () => {
          try {
            const result = await createOneEmailCampaignMutation({
              variables: {
                params: {
                  title: `New Campaign ${moment().format("MM/DD/YYYY hh:mma")}`,
                },
              },
            });
            if (result?.data?.createOneEmailCampaign?.id) {
              navigate(
                `/app/email-campaigns/editor/${result?.data?.createOneEmailCampaign?.id}`
              );
            }
          } catch (err) {
            console.log(err);
          }
        }}
      >
        New Email Campaign
      </Button>
      <Table
        dataSource={data?.getManyEmailCampaigns?.results}
        columns={[
          {
            title: "Name",
            render: (row: EmailCampaignFragmentFragment) => (
              <>
                <Link
                  to={
                    row?.status !== EmailCampaignStatusEnum.Draft
                      ? `/app/email-campaigns/${row?.id}`
                      : `/app/email-campaigns/editor/${row?.id}`
                  }
                >
                  {row?.title}
                </Link>{" "}
                <Caption>{row?.description}</Caption>
              </>
            ),
          },
          {
            title: "Status",
            render: (row: EmailCampaignFragmentFragment) => (
              <>
                <Caption>{row?.status?.toUpperCase()}</Caption>
              </>
            ),
          },
          {
            title: "Delivered",
            render: (row: EmailCampaignFragmentFragment) => (
              <>
                <Caption>{row?.delivered}</Caption>
              </>
            ),
          },
          {
            title: "Recipients",
            render: (row: EmailCampaignFragmentFragment) => (
              <>
                <Caption>{row?.recipients}</Caption>
              </>
            ),
          },
          {
            title: "Open %",
            render: (row: EmailCampaignFragmentFragment) => (
              <>
                <Caption>{row?.openRate}%</Caption>
              </>
            ),
          },
          {
            title: "Bounce %",
            render: (row: EmailCampaignFragmentFragment) => (
              <>
                <Caption>{row?.bounceRate}%</Caption>
              </>
            ),
          },
          {
            title: "Updated",
            render: (row: EmailCampaignFragmentFragment) => (
              <Tooltip
                title={moment(row?.updatedAt).format("MM/DD/YYYY hh:mma")}
              >
                <Caption>
                  {moment(row?.updatedAt).format("MMM, D YYYY")}
                </Caption>
              </Tooltip>
            ),
          },
          {
            title: "Created",
            render: (row: EmailCampaignFragmentFragment) => (
              <Tooltip
                title={moment(row?.createdAt).format("MM/DD/YYYY hh:mma")}
              >
                <Caption>
                  {moment(row?.createdAt).format("MMM, D YYYY")}
                </Caption>
              </Tooltip>
            ),
          },
        ]}
      />
    </PageContainer>
  );
}
