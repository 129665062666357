import styled from "styled-components";
// Components
import InteractionCard from "components/detail-components/Interactions/InteractionCard";
import EmptyState from "./EmptyState";
import {
  InteractionsQueryVariables,
  useGetManyEmailThreadsQuery,
  useInteractionsQuery,
} from "generated/graphql";
import { useState } from "react";
import { Segmented, Collapse } from "antd";
import Loading from "components/common/Loading";
import ErrorBlock from "components/common/ErrorBlock";

export const Title = styled.h2`
  margin: 0px;
  font-size: 16px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.colors.neutral1};
  margin-top: 16px;
`;

export const Description = styled.h3`
  margin: 0px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${(p) => p.theme.colors.neutral1};
`;

export const Card = styled.div`
  box-shadow: ${({ theme }) => theme.boxShadow};
  padding: 16px;
  border-radius: 7px;
  margin: auto;
  margin-bottom: 16px;
  background: #fff;
  position: relative;
  width: 600px;
  max-width: 100%;
  margin-top: 16px;
`;

export const Name = styled.h3`
  font-weight: 500;
  margin: 0px;
  color: ${({ theme }) => theme.colors.neutral1};
  font-size: 14px;
  line-height: 20px;
`;

export const CreatedAt = styled.p`
  font-weight: 400;
  margin: 0px;
  color: ${({ theme }) => theme.colors.neutral5};
  font-size: 14px;
  line-height: 20px;
`;

enum InteractionQueryTypeEnum {
  EMAIL_THREAD = "EMAIL_THREAD",
  ALL_INTERACTIONS = "ALL_INTERACTIONS",
}

const options = [
  {
    value: InteractionQueryTypeEnum.ALL_INTERACTIONS,
    label: "All Interactions",
  },
  {
    value: InteractionQueryTypeEnum.EMAIL_THREAD,
    label: "Email Threads",
  },
];

export default function Interactions({
  queryVariables,
}: {
  queryVariables?: InteractionsQueryVariables;
}) {
  const [queryType, setQueryType] = useState<InteractionQueryTypeEnum>(
    InteractionQueryTypeEnum.ALL_INTERACTIONS
  );

  const { data, loading, error } = useInteractionsQuery({
    variables: {
      ...queryVariables,
    },
    skip: queryType === InteractionQueryTypeEnum.EMAIL_THREAD,
  });

  const { data: threadQuery } = useGetManyEmailThreadsQuery({
    variables: {
      itemId: queryVariables?.contactId || queryVariables?.businessId || "",
    },
    skip:
      queryType === InteractionQueryTypeEnum.ALL_INTERACTIONS ||
      (!queryVariables?.contactId && !queryVariables?.businessId),
  });

  if (loading) return <Loading />;
  if (error) {
    return (
      <ErrorBlock error={"Something went wrong loading your interactions"} />
    );
  }

  const items = data?.interactions?.results || threadQuery?.getManyEmailThreads;

  return (
    <div
      style={{
        paddingTop: 16,
        maxWidth: "100%",
      }}
    >
      {(queryVariables?.contactId || queryVariables?.businessId) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Segmented
            options={options?.map((option) => ({
              value: option.value,
              label: option.label,
            }))}
            value={queryType}
            onChange={(value) =>
              setQueryType(value as InteractionQueryTypeEnum)
            }
          />{" "}
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: 60,
          paddingTop: 16,
          maxWidth: "100%",
        }}
      >
        <div style={{ maxWidth: "100%" }}>
          {!items?.[0] && <EmptyState />}
          {queryType === InteractionQueryTypeEnum.ALL_INTERACTIONS
            ? data?.interactions?.results?.map((interaction) => (
                <InteractionCard
                  key={interaction.id}
                  interaction={interaction}
                />
              ))
            : threadQuery?.getManyEmailThreads?.map((thread) => (
                <ThreadCard key={thread?.id}>
                  <Collapse>
                    <Collapse.Panel
                      key={thread?.id}
                      header={<ThreadTitle>{thread?.subject}</ThreadTitle>}
                    >
                      {thread?.interactions?.map((interaction) => (
                        <InteractionCard
                          key={interaction?.id}
                          interaction={interaction}
                        />
                      ))}
                    </Collapse.Panel>
                  </Collapse>
                </ThreadCard>
              ))}
        </div>
      </div>
    </div>
  );
}

const ThreadCard = styled(Card)`
  margin-bottom: 16px;
`;

const ThreadTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  color: ${({ theme }) => theme.colors.neutral1};
`;
