import { useState } from "react";
import styled from "styled-components";
// COMPONENTS
import FormItem from "components/common/FormItem";
import TextInput from "components/inputs/TextInput";
import TextEditor from "components/inputs/TextEditor";
import DateInput from "components/inputs/DateInput";
import TextAreaInput from "components/inputs/TextAreaInput";
import Button from "components/common/Button";
import Row from "components/common/Row";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import Col from "components/common/Col";
import CaseServicesInput from "components/inputs/CaseServicesInput";
import CaseStatusInput from "components/inputs/CaseStatusInput";
import CaseCategoryInput from "components/inputs/CaseCategoryInput";
import ContactInput from "components/inputs/ContactInputSimple";
import BusinessInput from "components/inputs/BusinessInput";
import { useApolloClient } from "@apollo/client";
import Attachments from "components/common/Attachments";
import FormBreak from "components/common/FormBreak";
import SideFormButtonContainer from "components/common/SideFormButtonContainer";
// Hooks

// LIb
import helpers from "lib/helpers/GeneralHelpers";
// APOLLO
import {
  BusinessesDocument,
  BusinessesQuery,
  BusinessesQueryVariables,
  CaseTemplateFragmentFragment,
  CustomerFeatureSettingKeyEnum,
  useCaseStatusesQuery,
  useGetManyCaseTemplatesQuery,
  useTeamQuery,
} from "generated/graphql";
import useUploadFile from "lib/hooks/useUploadFile";
import getTextEditorValueLength from "lib/helpers/getTextEditorValueLength";
import ProjectInput from "components/inputs/ProjectInput";
import SelectInput from "components/inputs/SelectInput";
import getFullName from "lib/helpers/GeneralHelpers/getFullName";
import theme from "lib/theme";
import LinkButton from "components/common/LinkButton";
import Text from "components/text/Text";
import { CaseTemplateTodos } from "../CaseTemplateForm";
import featureIsOn from "lib/helpers/featureIsOn";
import useCurrentUser from "lib/hooks/useCurrentUser";

const Form = styled.form``;

const UploadButton = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const UploadLabel = styled.label`
  font-weight: 600;
  color: ${(p) => p.theme.colors.primary2};
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  transition:
    color 0.3s ease,
    background-color 0.3s ease,
    border-color 0.3s ease,
    width 0.3s ease,
    opacity 0.3s ease;
  &:hover {
    color: ${(p) => p.theme.colors.primary1};
  }
`;

interface CaseFormErrorsState {
  title?: string;
  description?: string;
  caseStatusId?: string;
  caseCategoryId?: string;
  resolvedOn?: string;
  serviceIds?: string;
  assignedToId?: string;
  dueDate?: string;
  businessId?: string;
  contactId?: string;
  resolutionNotes?: string;
  projectId?: string;
  reporterId?: string;
}

const CaseTemplateInput = ({
  onTemplateChange,
}: {
  onTemplateChange: (args: CaseTemplateFragmentFragment) => void;
}) => {
  const [caseTemplateId, setCaseTemplateid] = useState(undefined);
  const [selectable, setSelectable] = useState(false);

  const { data, loading } = useGetManyCaseTemplatesQuery();

  if (!loading && !data?.getManyCaseTemplates?.[0]) {
    return (
      <Text>
        Want to create a reusable case template?{" "}
        <Link to="/app/cases/templates">Click here</Link>
      </Text>
    );
  }

  if (loading || !data?.getManyCaseTemplates?.[0]) return null;

  if (!selectable) {
    return (
      <Text>
        Want to use a template?{" "}
        <LinkButton onClick={() => setSelectable(true)}>Click here</LinkButton>
      </Text>
    );
  }

  return (
    <>
      <FormBreak
        title="Template"
        description="Use one of your saved case templates to expedite creating this case"
        topBorder
      />
      <FormItem label="Case Template">
        <SelectInput
          value={caseTemplateId}
          onChange={(caseTemplateId) => {
            setCaseTemplateid(caseTemplateId);
            const template = data?.getManyCaseTemplates?.find(
              (template) => template?.id === caseTemplateId
            );
            if (template) {
              onTemplateChange(template);
            }
          }}
          options={
            data?.getManyCaseTemplates?.map((item) => {
              return {
                id: item?.id || "",
                label: item?.templateName || "",
              };
            }) || []
          }
          containerStyle={{
            width: "100%",
            backgroundColor: theme.colors?.neutral11,
          }}
          data-testid="case-form-case-template"
        />
      </FormItem>
      <Text>
        To manage your templates{" "}
        <Link to="/app/cases/templates">click here</Link>
      </Text>
      <FormBreak
        title="Case details"
        description="Fill in the rest of the values for your case"
        topBorder
      />
    </>
  );
};

export default function CaseForm({
  defaultValues,
  loading,
  onSubmit,
  onCancel,
  submitBtnText,
}) {
  const client = useApolloClient();
  const [values, setValues] = useState({
    ...defaultValues,
  });
  const currentUser = useCurrentUser();
  const [errors, setErrors] = useState<CaseFormErrorsState>({});
  const [uploadFile, uploading] = useUploadFile();
  const { data, loading: caseStatusesLoading } = useCaseStatusesQuery({
    onCompleted: (data) => {
      if (!values.caseStatusId) {
        setValues((oldValues) => ({
          ...oldValues,
          caseStatusId: data?.caseStatuses?.[0]?.id,
        }));
      }
    },
  });
  const { data: teamQuery } = useTeamQuery();

  const caseStatuses = !caseStatusesLoading && data?.caseStatuses;
  const caseStatus =
    caseStatuses &&
    caseStatuses.filter((status) => status?.id === values.caseStatusId)[0];

  const handleSubmit = (e) => {
    try {
      setErrors({});
      e && e.preventDefault();
      if (!values.title) {
        setErrors({
          ...errors,
          title: "Please provide a title",
        });
        return;
      }

      if (!values.description) {
        return setErrors({
          ...errors,
          description: "Please provide a description",
        });
      }

      if (!values.caseStatusId) {
        return setErrors({
          ...errors,
          caseStatusId: "Please provide a status",
        });
      }

      if (!values.caseCategoryId) {
        return setErrors({
          ...errors,
          caseCategoryId: "Please provide a category",
        });
      }

      if (caseStatus && caseStatus.isFinalStep && !values.resolvedOn) {
        return setErrors({
          ...errors,
          resolvedOn: "A resolved on date is required for resolved cases",
        });
      }

      onSubmit({
        ...values,
        description: values?.description,
        dueDate: values?.dueDate?.valueOf()?.toString(),
        attachments:
          values.attachments &&
          helpers.cleanTypenameFromArray(values.attachments),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (newValues) => {
    setValues((oldValues) => ({
      ...oldValues,
      ...newValues,
    }));
  };

  const onBlur = () => setErrors({});

  const onContactChange = async (contactId: string) => {
    const contactHasChanged = values?.contactId !== contactId;
    const businessIsEmpty = !values?.businessId;
    if (contactHasChanged && businessIsEmpty) {
      const result = await client.query<
        BusinessesQuery,
        BusinessesQueryVariables
      >({
        query: BusinessesDocument,
        variables: {
          params: {
            contactIds: [contactId],
          },
          limit: 1,
        },
      });
      // if the business field is empty,
      // search for businesses for this contact
      // assignt he first business they are assocated with
      if (result?.data?.businesses?.businesses?.[0]?.id) {
        handleChange({
          businessId: result?.data?.businesses?.businesses?.[0]?.id,
        });
      }
    }
  };

  const onBusinessChange = async (businessId: string) => {
    const businessHasChanged = values?.businessId !== businessId;
    const contactIsEmpty = !values?.contactId;
    if (businessHasChanged && contactIsEmpty) {
      const result = await client.query<
        BusinessesQuery,
        BusinessesQueryVariables
      >({
        query: BusinessesDocument,
        variables: {
          businessIds: [businessId],
          limit: 1,
        },
      });
      // if the business field is empty,
      // search for businesses for this contact
      // assignt he first business they are assocated with
      if (result?.data?.businesses?.businesses?.[0]?.contactIds?.[0]) {
        handleChange({
          contactId: result?.data?.businesses?.businesses?.[0]?.contactIds?.[0],
        });
      }
    }
  };

  const disabled =
    //!values?.businessId ||
    !values?.title ||
    !values?.caseStatusId ||
    !values?.caseCategoryId ||
    getTextEditorValueLength(values?.description) < 1 ||
    loading;

  return (
    <Form onSubmit={handleSubmit}>
      {!defaultValues?.title?.[0] && (
        <CaseTemplateInput
          onTemplateChange={(newTemplate) => {
            handleChange({
              title: newTemplate?.title,
              description: newTemplate?.description,
              caseCategoryId: newTemplate?.caseCategoryId,
              todos: newTemplate?.todos,
            });
          }}
        />
      )}
      <FormItem label="Title" error={errors?.title} required>
        <TextInput
          value={values.title}
          onChange={(title) => handleChange({ title })}
          data-testid="case-form-title"
          onBlur={onBlur}
        />
      </FormItem>
      <FormItem label="Description" required error={errors?.description}>
        <TextEditor
          value={values.description}
          onChange={(description) => handleChange({ description })}
        />
      </FormItem>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <FormItem label="Cateogry" error={errors?.caseCategoryId} required>
            <CaseCategoryInput
              value={values.caseCategoryId}
              onChange={(caseCategoryId) => handleChange({ caseCategoryId })}
              data-testid="case-form-caseCategoryId"
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={12}>
          <FormItem label="Status" error={errors.caseStatusId} required>
            <CaseStatusInput
              value={values.caseStatusId}
              onChange={(caseStatusId) => handleChange({ caseStatusId })}
              data-testid="case-form-caseStatusId"
            />
          </FormItem>
        </Col>
      </Row>
      <FormItem
        label="Services Requested"
        error={errors?.serviceIds}
        hint="Which services does the business need help with?"
      >
        <CaseServicesInput
          value={values.serviceIds}
          onChange={(serviceIds) => handleChange({ serviceIds })}
          data-testid="case-form-serviceIds"
        />
      </FormItem>
      <Row gutter={16}>
        <Col xs={24} sm={16}>
          <FormItem label="Assigned To" error={errors.assignedToId}>
            <SelectInput
              data-testid="case-form-assigned-to-selectinput"
              value={values.assignedToId}
              options={teamQuery?.team?.map((user) => ({
                id: user?.id || "",
                label: getFullName(user) || "",
              }))}
              containerStyle={{
                width: "100%",
                backgroundColor: theme.colors?.neutral11,
              }}
              onChange={(assignedToId) => handleChange({ assignedToId })}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={8}>
          <FormItem label="Due Date" error={errors.dueDate}>
            <DateInput
              value={values.dueDate}
              showTime={false}
              format={`M/D/YY`}
              onChange={(dueDate) => handleChange({ dueDate })}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={16}>
          <FormItem label="Reporter" error={errors.reporterId}>
            <SelectInput
              data-testid="case-form-reporterId-selectinput"
              value={values.reporterId}
              options={teamQuery?.team?.map((user) => ({
                id: user?.id || "",
                label: getFullName(user) || "",
              }))}
              containerStyle={{
                width: "100%",
                backgroundColor: theme.colors?.neutral11,
              }}
              onChange={(reporterId) => handleChange({ reporterId })}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={8}></Col>
      </Row>

      {values?.todos?.[0] ? (
        <>
          <FormBreak
            title="Todos"
            description="Set the todos associated with this task"
            topBorder
          />
          <CaseTemplateTodos todos={values?.todos} onTodosChange={() => {}} />
        </>
      ) : null}
      <Col xs={24}>
        <FormBreak
          title="Relations"
          description="Associate this with a business, contact or project"
          topBorder
        />
        <FormItem label="Contact" error={errors?.contactId}>
          <ContactInput
            value={values.contactId}
            onChange={(contactId) => {
              onContactChange(contactId);
              handleChange({ contactId });
            }}
            defaultContactId={values.contactId}
            businessId={values.businessId}
          />
        </FormItem>
        <FormItem label="Business" error={errors.businessId}>
          <BusinessInput
            value={values.businessId}
            canCreateNew
            onChange={(businessId) => {
              onBusinessChange(businessId);
              handleChange({ businessId });
            }}
            defaultBusinessId={values.businessId} // used to ensure the query includes this ID
          />
        </FormItem>
        {featureIsOn({
          customer: currentUser?.customer,
          feature: CustomerFeatureSettingKeyEnum.Projects,
        }) && (
          <FormItem label="Project" error={errors.projectId}>
            <ProjectInput
              value={values.projectId}
              onChange={(projectId) => handleChange({ projectId })}
              defaultProjectId={values.projectId} // used to ensure the query includes this ID
            />
          </FormItem>
        )}
      </Col>

      <Row gutter={12}>
        {/* <Col xs={12} /> */}
        {caseStatus && caseStatus.isFinalStep && (
          <>
            <Col xs={24}>
              <FormItem label="Resolution Notes" error={errors.resolutionNotes}>
                <TextAreaInput
                  value={values.resolutionNotes}
                  rows="4"
                  onChange={(resolutionNotes) =>
                    handleChange({ resolutionNotes })
                  }
                />
              </FormItem>
            </Col>
            <Col xs={24}>
              <FormItem
                label="Resolved on Date"
                error={errors.resolvedOn}
                required
              >
                <DateInput
                  value={values.resolvedOn}
                  onChange={(resolvedOn) => handleChange({ resolvedOn })}
                  showTime={false}
                  format={`M/D/YY`}
                />
              </FormItem>
            </Col>
          </>
        )}
        <Col xs={24}>
          <FormBreak
            title="Attachments"
            description="Add as many attachments as you'd like"
            topBorder
          />
          <Attachments
            attachments={values.attachments || []}
            onSave={(attachments) => handleChange(attachments)}
          />
          <div style={{ paddingTop: 24, paddingBottom: 150 }}>
            {!uploading ? (
              <>
                {" "}
                <UploadLabel htmlFor="case-attachment-input">
                  + Upload an attachment
                </UploadLabel>
                <UploadButton
                  id="case-attachment-input"
                  name="case-attachment-input"
                  type="file"
                  onChange={async (e) => {
                    const file = e?.target?.files?.[0];
                    if (!file) return;
                    let result = await uploadFile(file);
                    handleChange({
                      attachments: [...(values.attachments || []), result],
                    });
                  }}
                />
              </>
            ) : (
              <div style={{ height: 40 }}>
                <LoadingOutlined /> Uploading...
              </div>
            )}
          </div>
        </Col>
      </Row>

      <SideFormButtonContainer>
        {onCancel && (
          <Button
            disabled={loading}
            grey
            style={{ width: 100, marginRight: 16 }}
            onClick={onCancel}
          >
            Cancel
          </Button>
        )}
        <Button
          disabled={disabled}
          loading={loading}
          style={{ width: 160 }}
          onClick={handleSubmit}
          data-testid="case-form-submit-btn"
        >
          {submitBtnText || "Submit"}
        </Button>
      </SideFormButtonContainer>
    </Form>
  );
}
